



































import { Component, Vue } from 'vue-property-decorator';
import { PartnerDocument } from '@/models';
import PartnerStore from '@/store/modules/partner';
import { GreyBox } from '@/components';
import ElTableDraggable from 'element-ui-el-table-draggable';

@Component({
  name: 'PartnerMetadata',
  components: {
    GreyBox,
    ElTableDraggable,
  },
})
export default class extends Vue {
  private partner: PartnerDocument = PartnerStore.current as PartnerDocument;

  private addToList(): void {
    this.partner.variations.push('');
  }

  private removeFromList(index: number): void {
    this.partner.variations.splice(index, 1);
  }
}
