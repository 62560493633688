


























import { Component, Prop, Vue } from 'vue-property-decorator';
import { PartnerWorkoutDocument, IPartnerWorkoutBrandVariation } from '@/models';
import PartnerWorkoutStore from '@/store/modules/partnerworkout';
import { IPartnerWorkoutExerciseOption } from '../util';
import { routeHref } from '@/util/helpers';

@Component({
  name: 'PartnerWorkoutSummary',
})
export default class extends Vue {
  @Prop({ required: true }) private exercises!: IPartnerWorkoutExerciseOption[];

  private partnerWorkout: PartnerWorkoutDocument = PartnerWorkoutStore.current as PartnerWorkoutDocument;
  private variationWorkouts: IPartnerWorkoutBrandVariation[] = this.partnerWorkout.variationWorkouts.filter(x => !x.isDisabled);
  private movementTypeLength: number = this.variationWorkouts[0].exerciseSetup.length;
  private colSpan: number = Math.floor(23 / this.variationWorkouts.length);

  private getExerciseName(exerciseId: string): string {
    return this.exercises.find(x => x.key === exerciseId)?.value as string;
  }

  private gotoExercise(exerciseId: string): void {
    const href = routeHref(
      {
        name: 'exercise-update',
        params: { id: exerciseId },
      },
      'athletics',
    );

    window.open(href, '_blank');
  }

  private hasExercise(setup: IPartnerWorkoutBrandVariation, index: number): boolean {
    return !!setup.exerciseSetup[index] && !!setup.exerciseSetup[index].exerciseId;
  }
}
