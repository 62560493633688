

























import { Component, Vue } from 'vue-property-decorator';
import { PartnerBrandDocument, IPartnerBrandVariationMovementSetup, IBrandWorkoutSetup } from '@/models';
import PartnerBrandStore from '@/store/modules/partnerbrand';
import { PartnerBrandMetadata, PartnerBrandMovementTypes, PartnerBrandWarmupExercises } from './sections';
import { Helper, IStepSetup } from '@/util';

@Component({
  name: 'CreateWorkout',
})
export default class extends Vue {
  private partnerbrand: PartnerBrandDocument = PartnerBrandStore.current as PartnerBrandDocument;
  private step = 0;
  private steps: Array<IStepSetup> = (undefined as unknown) as Array<IStepSetup>;

  public created() {
    this.steps = [
      {
        label: 'Metadata',
        id: 'metadata',
        component: PartnerBrandMetadata,
      },
      {
        label: 'Movement types',
        id: 'movementTypes',
        component: PartnerBrandMovementTypes,
      },
      {
        label: 'Warmup & Cooldown',
        id: 'warmupCooldownExercises',
        component: PartnerBrandWarmupExercises,
      },
    ];
  }

  get title(): string {
    const title = this.partnerbrand.isNew() ? `Create ${this.partnerbrand.name} Partner Brand` : `${this.partnerbrand.name} Partner Brand`;
    const step = this.steps[this.step];
    return `${title} - ${step.label}`;
  }

  get component(): any {
    const step = this.getStep();
    if (step.component) {
      return step.component;
    }
    return null;
  }
  private gotoStep(stepId: string) {
    const stepIndex = this.steps.findIndex(step => {
      return step.id === stepId;
    });
    if (stepIndex > -1) {
      this.step = stepIndex;
    }
  }

  private getStep(step: number = this.step) {
    return this.steps[step];
  }

  public nextStep(): void {
    const { step, steps } = this;
    if (step + 1 < steps.length) {
      this.step = step + 1;
    }
  }

  public async saveForm(): Promise<void> {
    try {
      this.partnerbrand.variationMovementSetup = this.partnerbrand.variationMovementSetup.reduce(
        (accumulator, setup) => [...accumulator, { variation: setup.variation, movementTypes: setup.movementTypes.filter(x => !!x) }],
        [] as IPartnerBrandVariationMovementSetup[],
      );

      this.partnerbrand.workoutSetup = this.partnerbrand.workoutSetup.reduce(
        (accumulator, setup) => [...accumulator, { type: setup.type, setup: setup.setup.filter(x => !!x.exerciseId) }],
        [] as IBrandWorkoutSetup[],
      );

      const isNew = this.partnerbrand.isNew();

      await this.partnerbrand.validate();

      await this.partnerbrand.save();

      await Helper.routeTo({ name: 'list' });

      this.$message({
        message: `Partner Brand ${this.partnerbrand.name} successfully ${isNew ? 'created' : 'updated'}.`,
        type: 'success',
      });
    } catch (e) {
      let errorMsg = 'Error raised when saving';
      if (Array.isArray(e)) {
        const errorList = e.map(error => error.message);
        errorMsg = `"${errorList.join('" & "')}"`;
      }
      this.$message({
        message: `Save failed: ${errorMsg}`,
        type: 'error',
        duration: 5000,
      });

      console.error('ERROR', e, errorMsg);
    }
  }

  private async gotoList() {
    await Helper.routeTo({ name: 'list' });
  }

  public prevStep(): void {
    const { step } = this;
    if (step - 1 >= 0) {
      this.step = step - 1;
    }
  }

  public isLastStep(): boolean {
    return this.step + 1 === this.steps.length;
  }
}
