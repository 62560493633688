
















import { Component, Vue } from 'vue-property-decorator';
import { PartnerDocument } from '@/models';
import PartnerStore from '@/store/modules/partner';
import { PartnerManageMetadata } from './sections';
import { Helper, IStepSetup } from '@/util';

@Component({
  name: 'CreateWorkout',
})
export default class extends Vue {
  private partner: PartnerDocument = PartnerStore.current as PartnerDocument;
  private step = 0;
  private steps: Array<IStepSetup> = (undefined as unknown) as Array<IStepSetup>;

  public created() {
    this.steps = [
      {
        label: 'Metadata',
        id: 'metadata',
        component: PartnerManageMetadata,
      },
    ];
  }

  get title(): string {
    const title = this.partner.isNew() ? `Create ${this.partner.name} Partner` : `${this.partner.name} Partner`;
    const step = this.steps[this.step];
    return `${title} - ${step.label}`;
  }

  get component(): any {
    const step = this.getStep();
    if (step.component) {
      return step.component;
    }
    return null;
  }

  private getStep(step: number = this.step) {
    return this.steps[step];
  }

  public async saveForm(): Promise<void> {
    try {
      const isNew = this.partner.isNew();

      await this.partner.validate();

      await this.partner.save();

      await Helper.routeTo({ name: 'list' });

      this.$message({
        message: `Partner ${this.partner.name} successfully ${isNew ? 'created' : 'updated'}.`,
        type: 'success',
      });
    } catch (e) {
      let errorMsg = 'Error raised when saving';
      if (Array.isArray(e)) {
        const errorList = e.map(error => error.message);
        errorMsg = `"${errorList.join('" & "')}"`;
      }
      this.$message({
        message: `Save failed: ${errorMsg}`,
        type: 'error',
        duration: 5000,
      });

      console.log(e);
    }
  }

  private async gotoList() {
    await Helper.routeTo({ name: 'list' });
  }
}
