


























































































import { Component, Vue } from 'vue-property-decorator';
import { CategoryType, CategoryTypeList } from '@/constants';
import { PartnerDocument, PartnerModel, PartnerBrandDocument, PartnerBrandModel } from '@/models';
import { Helper } from '@/util';

interface IBrandSearch {
  partnerIdx: string[];
  categoryTypes: CategoryType[];
  trainers: string[];
}

@Component({
  name: 'PartnerBrandList',
})
export default class extends Vue {
  private isSearching: boolean = false;
  private results: PartnerBrandDocument[] = [];
  private resultSlice: PartnerBrandDocument[] = [];
  private resultSliceSize: number = 50;
  private totalResults: number = 0;
  private page: number = 1;
  private showPartnerSelect: boolean = false;
  private selectedPartnerId: string = '';

  private categories: Record<CategoryType, string> = (null as unknown) as Record<CategoryType, string>;
  private partners: PartnerDocument[] = (null as unknown) as PartnerDocument[];
  private trainers: string[] = (null as unknown) as string[];
  private trainerBrandMap: Record<string, string[]> = (null as unknown) as Record<string, string[]>;

  private search: IBrandSearch = {
    partnerIdx: [],
    categoryTypes: [],
    trainers: [],
  };

  private async created() {
    this.categories = CategoryTypeList;
    this.partners = await PartnerModel.find({});

    const brands = await PartnerBrandModel.find({});

    this.trainers = [];
    this.trainerBrandMap = {};

    brands.forEach(x => {
      if (!this.trainerBrandMap[x.trainer.name]) {
        this.trainerBrandMap[x.trainer.name] = [];
        this.trainers.push(x.trainer.name);
      }

      this.trainerBrandMap[x.trainer.name].push(x.id);
    });
  }

  private mounted() {
    this.doSearch();
  }

  private async doSearch(): Promise<void> {
    if (this.isSearching) {
      return;
    }

    this.isSearching = true;

    const _queryFilters = [];
    const _partnerBrandIdList: string[] = this.search.partnerIdx;

    if (this.search.trainers.length > 0) {
      this.search.trainers.forEach(x => _partnerBrandIdList.push(...this.trainerBrandMap[x]));
    }

    if (_partnerBrandIdList.length > 0) {
      _queryFilters.push(Helper.buildOrQuery('brandPartnerId', _partnerBrandIdList));
    }

    if (this.search.categoryTypes.length > 0) {
      _queryFilters.push(Helper.buildOrQuery('category', this.search.categoryTypes));
    }

    this.results = await PartnerBrandModel.find(_queryFilters.length === 0 ? {} : { and: _queryFilters });
    this.totalResults = this.results.length;
    this.setPagination(1);

    this.isSearching = false;
  }

  private setPagination(newPage: number): void {
    this.page = newPage;
    const startPos: number = Math.max(0, this.resultSliceSize * (newPage - 1));

    this.resultSlice = this.results.slice(startPos, startPos + this.resultSliceSize);
  }

  private gotoPartnerBrand(partnerId: string): void {
    Helper.routeTo({ name: 'update', params: { id: partnerId } });
  }

  private handleCreateNew(): void {
    if (!this.selectedPartnerId) {
      return;
    }

    Helper.routeTo({ name: 'create', params: { partnerId: this.selectedPartnerId } });
  }

  private async handleDeletePartnerBrand(partnerBrand: PartnerBrandDocument): Promise<void> {
    try {
      await partnerBrand.remove();
      this.$message({
        message: `Partner Brand ${partnerBrand.name} successfully deleted.`,
        type: 'success',
      });
      await this.doSearch();
    } catch (e) {
      this.$alert((e as Error).message, `Partner Brand deletion failed`, { type: 'error' });
    }
  }
}
