


































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProductType, StatusType, WorkoutVideoStatusList, WorkoutVideoStatusType } from '@/constants';
import { PartnerWorkoutDocument, IPartnerWorkoutBrandVariation } from '@/models';
import PartnerWorkoutStore from '@/store/modules/partnerworkout';
import { IPartnerWorkoutExerciseOption } from '../util';
import { FileUploader } from '@/components';
import { routeHref } from '@/util/helpers';

@Component({
  name: 'PartnerWorkoutVideo',
  components: {
    FileUploader,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private exercises!: IPartnerWorkoutExerciseOption[];

  private partnerWorkout: PartnerWorkoutDocument = PartnerWorkoutStore.current as PartnerWorkoutDocument;
  private expandedProductTypes: string[] = ([] as unknown) as ProductType[];
  private workoutVideoStatusList: Record<WorkoutVideoStatusType, StatusType<WorkoutVideoStatusType>> = WorkoutVideoStatusList;
  private variationWorkouts: IPartnerWorkoutBrandVariation[] = this.partnerWorkout.variationWorkouts.filter(x => !x.isDisabled);

  private created(): void {
    const query = this.$router.currentRoute.query;

    if (query.variation) {
      this.expandedProductTypes = [query.variation as string];
    } else {
      this.expandedProductTypes = [this.variationWorkouts[0].variation];
    }
  }

  private getExerciseName(exerciseId: string): string {
    return this.exercises.find(x => x.key === exerciseId)?.value as string;
  }

  private gotoExercise(exerciseId: string): void {
    const href = routeHref({
      name: 'exercise-update',
      params: { id: exerciseId },
    });

    window.open(href, '_blank');
  }

  /**
   * @TODO All this needs to be in the model
   */
  private handleFileRemove(variation: string): void {
    const setup = this.variationWorkouts.find(x => x.variation === variation);

    if (setup?.status !== WorkoutVideoStatusType.READY_TO_APPROVE) {
      return;
    }
  }
}
