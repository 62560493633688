

































import { Component, Vue } from 'vue-property-decorator';
import { Helper, IStepSetup } from '@/util';
import { PartnerBrandDocument, PartnerWorkoutModel, PartnerWorkoutDocument, ExerciseModel } from '@/models';
import { IPartnerWorkoutExerciseOption } from './util';
import { PartnerWorkoutVariation, PartnerWorkoutSummary, PartnerWorkoutVideo } from './sections';
import PartnerBrandStore from '@/store/modules/partnerbrand';
import PartnerWorkoutStore from '@/store/modules/partnerworkout';

interface IWorkoutStep extends IStepSetup {
  isVariation: boolean;
}

interface IWorkoutStepVariation extends IWorkoutStep {
  availableExercises: IPartnerWorkoutExerciseOption[];
}

interface IWorkoutStepDetail extends IWorkoutStep {
  exercises: IPartnerWorkoutExerciseOption[];
}

@Component({
  name: 'PartnerWorkoutManage',
})
export default class extends Vue {
  private partnerWorkout: PartnerWorkoutDocument = PartnerWorkoutStore.current as PartnerWorkoutDocument;
  private rules = Helper.schemaToRules(PartnerWorkoutModel.getSchema());
  private step = 0;
  private steps: Array<IWorkoutStep> = [] as Array<IWorkoutStep>;

  private selectedBrand: PartnerBrandDocument = (undefined as unknown) as PartnerBrandDocument;

  public async created() {
    this.selectedBrand = PartnerBrandStore.current as PartnerBrandDocument;

    /**
     * Scratch the below. We need to use ALL exercises. I left the below in just in case if
     * this chnages
     *
     *
     * We load up all the available exercises for each variation here so we only do it once
     */
    // const exercises = await ExerciseModel.find({});
    // const exerciseMap: IPartnerWorkoutExerciseOptionMap = this.selectedBrand.variationMovementSetup.reduce(
    //   (accumulator, variationSetup) => ({
    //     ...accumulator,
    //     [variationSetup.variation]: exercises
    //       .reduce((exerciseAccumulator, exercise) => {
    //         if (exercise.movementTypes.find(x => variationSetup.movementTypes.includes(x))) {
    //           exerciseAccumulator.push({
    //             key: exercise.id,
    //             value: exercise.name,
    //             lower: exercise.name.toLocaleLowerCase(),
    //             movementTypes: exercise.movementTypes,
    //           });
    //         }

    //         return exerciseAccumulator;
    //       }, [] as IPartnerWorkoutExerciseOption[])
    //       .sort((a: any, b: any): number => {
    //         if (a.lower < b.lower) {
    //           return -1;
    //         } else if (a.lower > b.lower) {
    //           return 1;
    //         } else {
    //           return 0;
    //         }
    //       }),
    //   }),
    //   {} as IPartnerWorkoutExerciseOptionMap,
    // );

    /**
     * Load ALL exercises
     */
    const exercises: IPartnerWorkoutExerciseOption[] = (await ExerciseModel.find({}))
      .map(x => ({
        key: x.id,
        value: x.name,
        lower: x.name.toLocaleLowerCase(),
        movementTypes: x.movementTypes,
      }))
      .sort((a: any, b: any): number => {
        if (a.lower < b.lower) {
          return -1;
        } else if (a.lower > b.lower) {
          return 1;
        } else {
          return 0;
        }
      });

    this.steps = this.selectedBrand.variationMovementSetup.map(variationSetup => ({
      id: variationSetup.variation,
      isVariation: true,
      label: variationSetup.variation,
      availableExercises: exercises,
      component: PartnerWorkoutVariation,
    })) as IWorkoutStepVariation[];

    this.steps.push({
      id: 'partnerWorkoutSummary',
      isVariation: false,
      label: 'Summary',
      exercises,
      component: PartnerWorkoutSummary,
    } as IWorkoutStepDetail);

    this.steps.push({
      id: 'partnerWorkoutVideo',
      isVariation: false,
      label: 'Video',
      exercises,
      component: PartnerWorkoutVideo,
    } as IWorkoutStepDetail);

    const query = this.$router.currentRoute.query;
    const stepId: string = query.step as string;
    if (stepId) {
      this.gotoStep(stepId);
    }
  }

  get title(): string {
    return this.partnerWorkout.isNew() ? 'Create Workout' : `Workout ${this.partnerWorkout.sequence}`;
  }

  get component(): any {
    const step = this.getStep();
    if (step.component) {
      return step.component;
    }
    return null;
  }

  private gotoStep(stepId: string, exerciseIndex: number = -1) {
    const stepIndex = this.steps.findIndex(step => {
      return step.id === stepId;
    });
    if (stepIndex > -1) {
      this.step = stepIndex;
    }
    if (exerciseIndex > -1) {
      setTimeout(() => {
        const refName = `${stepId}-exercise-${exerciseIndex}`;
        const element = document.getElementById(refName);
        element && element.scrollIntoView();
      }, 200);
    }
  }

  private getStep(step: number = this.step): IWorkoutStep {
    return this.steps[step];
  }

  public showNextStep(): boolean {
    return this.step + 1 < this.steps.length;
  }

  public nextStep(): void {
    const { step, steps } = this;
    if (step + 1 < steps.length) {
      this.step = step + 1;
    }
  }

  public async disableWorkout(): Promise<void> {
    let confirmed = false;

    try {
      await this.$confirm('Are you sure you wish to delete this partner partnerWorkout?', 'Delete Partner Workout', { type: 'error' });
      confirmed = true;
    } catch (e) {
      confirmed = false;
    }

    if (confirmed) {
      await this.partnerWorkout.disable();

      await Helper.routeTo({ name: 'list', params: { id: this.partnerWorkout.workoutPartnerBrandId } });
    }
  }

  public async saveForm(): Promise<void> {
    try {
      const isNew = this.partnerWorkout.isNew();

      await this.partnerWorkout.validate();

      await this.partnerWorkout.save();

      await Helper.routeTo({ name: 'list', params: { id: this.partnerWorkout.workoutPartnerBrandId } });

      this.$message({
        message: `Partner Workout successfully ${isNew ? 'created' : 'updated'}.`,
        type: 'success',
      });
    } catch (e) {
      console.error('ERROR', e);
    }
  }

  public async cancelForm(): Promise<void> {
    await Helper.routeTo({ name: 'list', params: { id: this.partnerWorkout.workoutPartnerBrandId } });
  }

  public prevStep(): void {
    const { step } = this;
    if (step - 1 >= 0) {
      this.step = step - 1;
    }
  }
}
