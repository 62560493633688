


























import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  PartnerWorkoutModel,
  PartnerWorkoutDocument,
  IWorkoutBrandProductTypeExercise,
  IPartnerWorkoutBrandVariationExerciseSetup,
  IPartnerWorkoutBrandVariation,
} from '@/models';
import { MovementType, MovementTypeList } from '@/constants';
import { IPartnerWorkoutExerciseOption } from '../util';
import PartnerWorkoutStore from '@/store/modules/partnerworkout';

@Component({
  name: 'PartnerWorkoutMovementType',
})
export default class extends Vue {
  @Prop({ required: true }) private variation!: string;
  @Prop({ required: true }) private movementType!: MovementType;
  @Prop({ required: true }) private movementTypeIndex!: number;
  @Prop({ required: true }) private availableExercises!: IPartnerWorkoutExerciseOption[];

  private partnerWorkout: PartnerWorkoutDocument = PartnerWorkoutStore.current as PartnerWorkoutDocument;
  private selectedExerciseWrapper: string = '';
  private movementTypeSetup: IWorkoutBrandProductTypeExercise = (null as unknown) as IWorkoutBrandProductTypeExercise;
  private lastUsed: string = (null as unknown) as string;

  get title(): string {
    return `${MovementTypeList[this.movementType]}`;
  }

  get numTitle(): string {
    return `${this.movementTypeIndex + 1}. ${MovementTypeList[this.movementType]}`;
  }

  private created() {
    const variationSetup: IPartnerWorkoutBrandVariation = this.partnerWorkout.variationWorkouts.find(
      x => x.variation === this.variation,
    ) as IPartnerWorkoutBrandVariation;

    this.movementTypeSetup = variationSetup.exerciseSetup[this.movementTypeIndex] as IPartnerWorkoutBrandVariationExerciseSetup;

    if (this.movementTypeSetup.exerciseId) {
      this.selectedExerciseWrapper = this.availableExercises.find(x => x.key === this.movementTypeSetup.exerciseId)?.value as string;
    }

    this.setLastUsed();
  }

  private queryExercises(searchStr: string, cb: (results: IPartnerWorkoutExerciseOption[]) => void): void {
    const searchStrLower = searchStr.toLowerCase();
    const startsWith: IPartnerWorkoutExerciseOption[] = [];
    const theRest: IPartnerWorkoutExerciseOption[] = [];

    this.availableExercises.forEach(x => {
      const pos = x.lower.indexOf(searchStrLower);

      if (pos === 0) {
        startsWith.push(x);
      } else if (pos > 0) {
        theRest.push(x);
      }
    });

    const compareFunc = (a: IPartnerWorkoutExerciseOption, b: IPartnerWorkoutExerciseOption): number => {
      if (a.lower < b.lower) {
        return -1;
      } else if (a.lower > b.lower) {
        return 1;
      } else {
        return 0;
      }
    };

    startsWith.sort(compareFunc);
    theRest.sort(compareFunc);

    cb(startsWith.concat(theRest));
  }

  private handleExerciseSelect(exerciseOption: IPartnerWorkoutExerciseOption): void {
    this.movementTypeSetup.exerciseId = exerciseOption.key;

    this.setLastUsed();
  }

  /**
   * This is here to make sure that we default the wrapper value to the selected exercise
   */
  private handleExerciseBlur(): void {
    const selected = this.availableExercises.find(x => x.key === this.movementTypeSetup.exerciseId) as IPartnerWorkoutExerciseOption;

    if (!selected) {
      this.selectedExerciseWrapper = '';
    } else if (selected.value !== this.selectedExerciseWrapper) {
      this.selectedExerciseWrapper = selected.value;
    }
  }

  private async setLastUsed(): Promise<void> {
    this.lastUsed = '';

    if (this.movementTypeSetup && this.movementTypeSetup.exerciseId) {
      this.lastUsed = '...';

      const usedInWorkouts: PartnerWorkoutDocument[] = await PartnerWorkoutModel.find({
        workoutPartnerBrandId: { eq: this.partnerWorkout.workoutPartnerBrandId },
        searchExerciseList: { contains: this.movementTypeSetup.exerciseId },
      });

      if (usedInWorkouts.length > 0) {
        usedInWorkouts.sort((a, b) => a.sequence - b.sequence);

        const sequence: number[] = usedInWorkouts.map(x => x.sequence);
        const lastSequence: number = sequence.splice(-1, 1).pop() as number;

        this.lastUsed = `Last used in workout ${sequence.length > 0 ? sequence.join(', ') + ' and ' : ''}${lastSequence}`;
      } else {
        this.lastUsed = 'Never used';
      }
    }
  }
}
