

































































import { Component, Vue } from 'vue-property-decorator';
import { PartnerBrandDocument } from '@/models';
import PartnerBrandStore from '@/store/modules/partnerbrand';
import { FileUploader, GreyBox } from '@/components';
import { CategoryType, CategoryTypeList } from '@/constants';

@Component({
  name: 'PartnerBrandMetadata',
  components: {
    GreyBox,
    FileUploader,
  },
})
export default class extends Vue {
  private partnerBrand: PartnerBrandDocument = PartnerBrandStore.current as PartnerBrandDocument;
  private categoryTypes: Record<CategoryType, string> = CategoryTypeList;

  get status(): string {
    return this.partnerBrand.active ? 'Active' : 'Inactive';
  }
}
