
































import { Component, Vue } from 'vue-property-decorator';
import { GreyBox } from '@/components';
import { MovementType, MovementTypeList } from '@/constants';
import PartnerBrandStore from '@/store/modules/partnerbrand';
import { PartnerBrandDocument } from '@/models';

@Component({
  name: 'BrandMovementTypes',
  components: {
    GreyBox,
  },
})
export default class extends Vue {
  private partnerBrand: PartnerBrandDocument = PartnerBrandStore.current as PartnerBrandDocument;
  private movementTypeMap: Record<MovementType, string> = MovementTypeList;
  private visibleProductTypes: string[] = [];

  public created() {
    this.visibleProductTypes = [this.partnerBrand.variationMovementSetup[0].variation];
  }

  private addMovementType(movementTypes: any[], index: number) {
    movementTypes.splice(index, 0, null);
  }

  private removeMovementType(movementTypes: MovementType[], index: number) {
    movementTypes.splice(index, 1);
  }

  private handleCopyFromPrevious(index: number): void {
    this.partnerBrand.variationMovementSetup[index].movementTypes = this.partnerBrand.variationMovementSetup[index - 1].movementTypes.slice(0);
  }
}
