
























import { Component, Vue } from 'vue-property-decorator';
import { Helper } from '@/util';
import { PartnerDocument, PartnerModel } from '@/models';

@Component({
  name: 'PartnerList',
})
export default class extends Vue {
  private isSearching: boolean = false;
  private results: PartnerDocument[] = [];
  private resultSlice: PartnerDocument[] = [];
  private resultSliceSize: number = 50;
  private totalResults: number = 0;
  private page: number = 1;

  private mounted() {
    this.doSearch();
  }

  private async doSearch(): Promise<void> {
    if (this.isSearching) {
      return;
    }

    this.isSearching = true;

    this.results = await PartnerModel.find({});
    this.totalResults = this.results.length;
    this.setPagination(1);

    this.isSearching = false;
  }

  private setPagination(newPage: number): void {
    this.page = newPage;
    const startPos: number = Math.max(0, this.resultSliceSize * (newPage - 1));

    this.resultSlice = this.results.slice(startPos, startPos + this.resultSliceSize);
  }

  private gotoPartner(partnerId: string): void {
    Helper.routeTo({ name: 'update', params: { id: partnerId } });
  }

  private gotoCreateNew(): void {
    Helper.routeTo({ name: 'create' });
  }
}
