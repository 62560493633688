
























import { Component, Prop, Vue } from 'vue-property-decorator';
import { PartnerWorkoutDocument, IPartnerWorkoutBrandVariation } from '@/models';
import { MovementType } from '@/constants';
import PartnerWorkoutStore from '@/store/modules/partnerworkout';
import { IPartnerWorkoutExerciseOption } from '../util';
import PartnerWorkoutMovementType from './PartnerWorkoutMovementType.vue';

@Component({
  name: 'PartnerWorkoutVariation',
  components: {
    PartnerWorkoutMovementType,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private variation!: string;
  @Prop({ required: true }) private availableExercises!: IPartnerWorkoutExerciseOption[];

  private partnerWorkout: PartnerWorkoutDocument = PartnerWorkoutStore.current as PartnerWorkoutDocument;
  private setup: IPartnerWorkoutBrandVariation = this.partnerWorkout.variationWorkouts.find(
    x => x.variation === this.variation,
  ) as IPartnerWorkoutBrandVariation;

  get isDisabled(): boolean {
    return !!this.setup.isDisabled;
  }

  private getMovementTypeExercises(movementType: MovementType): IPartnerWorkoutExerciseOption[] {
    /**
     * Again, scratch the below. We need to have available ALL exercises, regardless of movement type
     */

    // return this.availableExercises.filter(x => x.movementTypes.includes(movementType));
    return this.availableExercises;
  }
}
