































































































































import { Component, Vue } from 'vue-property-decorator';
import { GreyBox } from '@/components';

import { BrandWorkoutSetupType, BrandWorkoutSetupTypeList, WorkoutVideoStatusType, WorkoutVideoStatusList, StatusType } from '@/constants';
import PartnerBrandStore from '@/store/modules/partnerbrand';
import {
  PartnerBrandDocument,
  PartnerWorkoutDocument,
  PartnerWorkoutModel,
  IPartnerWorkoutBrandVariation,
  IPartnerBrandVariationMovementSetup,
  ExerciseModel,
  ExerciseDocument,
} from '@/models';
import { Helper, CSV } from '@/util';
import { CSV as sharedCSV } from '@/shared';
import { saveAs } from 'file-saver';
import { S3ObjectInput } from '@/API';
import { Storage } from 'aws-amplify';
import moment from 'moment';

interface IWorkoutSetup {
  type: BrandWorkoutSetupType;
  name: string;
  exercises: ExerciseDocument[];
}

@Component({
  name: 'PartnerWorkoutList',
  components: {
    GreyBox,
  },
})
export default class extends Vue {
  private isSearching: boolean = false;
  private results: PartnerWorkoutDocument[] = [];
  private workoutSetup: IWorkoutSetup[] = ([] as unknown) as IWorkoutSetup[];
  private selectedPartnerBrand: string | null = null;
  private currentPartnerBrand: PartnerBrandDocument = (undefined as unknown) as PartnerBrandDocument;
  private lastPartnerBrandSearched: string | null = null;
  private partnerBrands: PartnerBrandDocument[] = (undefined as unknown) as PartnerBrandDocument[];
  private workoutVideoStatusList: Record<WorkoutVideoStatusType, StatusType<WorkoutVideoStatusType>> = (undefined as unknown) as Record<
    WorkoutVideoStatusType,
    StatusType<WorkoutVideoStatusType>
  >;

  private downloading = {
    main: false,
    prodTeam: false,
    warmupCooldown: false,
  };

  public created() {
    this.partnerBrands = PartnerBrandStore.list;
    this.workoutSetup = [];
    this.workoutVideoStatusList = WorkoutVideoStatusList;
    this.requestSearch();
  }

  private requestSearch() {
    const partnerBrandId = this.$router.currentRoute.params.id || this.selectedPartnerBrand;
    if (!partnerBrandId) {
      return;
    }
    this.selectedPartnerBrand = partnerBrandId;
    if (this.lastPartnerBrandSearched === partnerBrandId) {
      return;
    }

    this.lastPartnerBrandSearched = partnerBrandId;
    this.doSearch(this.selectedPartnerBrand);
    const partnerBrand = this.partnerBrands.find(partnerBrand => partnerBrand.id === this.selectedPartnerBrand);
    if (!partnerBrand) {
      throw new Error(`Selected brand ${this.selectedPartnerBrand} not found`);
    }
    this.currentPartnerBrand = partnerBrand;
    this.doWorkoutSetupSearch();
  }

  get partnerBrandName() {
    if (this.currentPartnerBrand) {
      return this.currentPartnerBrand.name;
    }
    return '';
  }

  private async handlePartnerBrandChange(partnerBrandId: string) {
    this.gotoWorkoutList(partnerBrandId);
  }

  private async doSearch(partnerBrandId: string): Promise<void> {
    if (this.isSearching) {
      return;
    }

    this.isSearching = true;

    this.results = await PartnerWorkoutModel.findByPartnerBrandId(partnerBrandId);
    this.results.sort((a, b) => a.sequence - b.sequence);
    this.lastPartnerBrandSearched = partnerBrandId;
    this.isSearching = false;
  }

  private async doWorkoutSetupSearch(): Promise<void> {
    const exerciseIDList: string[] = this.currentPartnerBrand.workoutSetup.reduce(
      (accumulator, setup) => [...accumulator, ...setup.setup.map(x => x.exerciseId)],
      [] as string[],
    );

    if (exerciseIDList.length > 0) {
      const exerciseResults = await ExerciseModel.find(Helper.buildOrQuery('id', exerciseIDList));

      this.workoutSetup = this.currentPartnerBrand.workoutSetup.reduce(
        (accumulator, setup) =>
          [
            ...accumulator,
            {
              type: setup.type,
              name: BrandWorkoutSetupTypeList[setup.type],
              exercises: setup.setup.map(x => exerciseResults.find(exercise => exercise.id === x.exerciseId)),
            },
          ] as IWorkoutSetup[],
        [] as IWorkoutSetup[],
      );
    }
  }

  private jsonListQuery(property: string, list: any[]): any {
    const queries = list.map(value => {
      return { [property]: { contains: value } };
    });
    return { or: queries };
  }

  private getVariationWorkout(workout: PartnerWorkoutDocument, variation: string): IPartnerWorkoutBrandVariation | null {
    return workout.variationWorkouts.find(item => item.variation === variation) || null;
  }

  private gotoWorkoutList(partnerBrandId: string) {
    Helper.routeTo({ name: 'list', params: { id: partnerBrandId } });
  }

  private gotoWorkoutCreate() {
    Helper.routeTo({ name: 'create', params: { partnerBrandId: this.selectedPartnerBrand as string } });
  }

  private gotoWorkoutUpdate(workoutId: string, variation?: string) {
    const step = variation as string;
    Helper.routeTo({ name: 'update', params: { id: workoutId }, query: { step } });
  }

  private gotoWorkoutSummary(workoutId: string): void {
    Helper.routeTo({ name: 'update', params: { id: workoutId }, query: { step: 'partnerWorkoutSummary' } });
  }

  private gotoWorkoutVideo(workoutId: string): void {
    Helper.routeTo({ name: 'update', params: { id: workoutId }, query: { step: 'partnerWorkoutVideo' } });
  }

  private gotoBrandWarmupCooldown(partnerBrandId: string): void {
    Helper.routeTo({ name: 'brand-update', params: { id: partnerBrandId, step: 'warmupCooldownExercises' } });
  }

  protected async downloadVideo(exerciseVideo: S3ObjectInput): Promise<void> {
    if (!exerciseVideo) {
      return;
    }

    const result = (await Storage.get(exerciseVideo.key, { download: true })) as Record<string, any>;

    saveAs(result.Body as Blob, exerciseVideo.name);
  }

  private async handleDeleteWorkout(workout: PartnerWorkoutDocument) {
    await workout.disable();

    this.$message({
      message: `Partner Workout ${workout.sequence} successfully deleted.`,
      type: 'success',
    });

    await this.doSearch(this.selectedPartnerBrand || '');
  }

  private async downloadWarmupCooldownCSV(): Promise<void> {
    this.downloading.warmupCooldown = true;

    await sharedCSV.brandWarmupCooldown(this.currentPartnerBrand);

    this.downloading.warmupCooldown = false;
  }

  private async downloadCSV(): Promise<void> {
    this.downloading.main = true;

    /**
     * @TODO Quick hack until we get error checking on the exercise <-> brand <-> workout fixed
     *
     * Get all the exercises used in this brand. Also workout max qpouints while we're here
     */
    let maxQPoints = 0;

    const exercises: Record<string, ExerciseDocument> = (await ExerciseModel.find()).reduce((accumulator, exercise: ExerciseDocument) => {
      maxQPoints = Math.max(maxQPoints, exercise.qpoints.length);
      return { ...accumulator, [exercise.id]: exercise };
    }, {} as Record<string, ExerciseDocument>);

    /**
     * Construct the csv download
     */
    const columnMap: any = this.results.reduce(
      (accumulator, workout) => ({
        ...accumulator,
        [`workout${workout.sequence}ExName`]: `Workout ${workout.sequence}`,
        ...new Array(maxQPoints)
          .fill('')
          .reduce(
            (accumulator: Record<string, string>, _val: any, key: number) => ({ ...accumulator, [`workout${workout.sequence}QP${key + 1}`]: `QP${key + 1}#` }),
            {},
          ),
      }),
      { name: this.currentPartnerBrand.name, exerciseNo: 'Exercise No#' },
    );

    const results: Array<any[] | null> = this.currentPartnerBrand.variationMovementSetup.reduce((accumulator, setup: IPartnerBrandVariationMovementSetup) => {
      accumulator.push(null);

      const exerciseLength: number = this.results.reduce(
        (lengthAccumulator, workout) =>
          Math.max(
            lengthAccumulator,
            (workout.variationWorkouts.find(x => x.variation === setup.variation) as IPartnerWorkoutBrandVariation).exerciseSetup.length,
          ),
        0,
      );

      for (let exerciseIndex = 0; exerciseIndex < exerciseLength; exerciseIndex++) {
        const productExercises = {
          name: setup.variation,
          exerciseNo: exerciseIndex + 1,
          ...this.results.reduce((workoutAccumulator, workout) => {
            const variationSetup = workout.variationWorkouts.find(x => x.variation === setup.variation && !x.isDisabled) as IPartnerWorkoutBrandVariation;

            if (!variationSetup) {
              return workoutAccumulator;
            }

            const exerciseSetup = variationSetup.exerciseSetup[exerciseIndex];

            if (!exerciseSetup) {
              return workoutAccumulator;
            }
            const exercise: ExerciseDocument = exercises[exerciseSetup.exerciseId];

            if (!exercise) {
              return workoutAccumulator;
            }

            workoutAccumulator[`workout${workout.sequence}ExName`] = exercise.name;

            exercise.qpoints.concat(new Array(maxQPoints - exercise.qpoints.length).fill('')).forEach((point, index) => {
              workoutAccumulator[`workout${workout.sequence}QP${index + 1}`] = point;
            });

            return workoutAccumulator;
          }, {} as any),
        };
        accumulator.push(productExercises);
      }

      return accumulator;
    }, [] as Array<any[] | null>);

    const csvStr: string = CSV.fromArray(results, columnMap);

    saveAs(
      'data:application/csv;charset=utf-8,' + encodeURIComponent(csvStr),
      `${this.currentPartnerBrand.name.toLowerCase().replace(/\s+/g, '-')}-workout-list.csv`,
    );

    this.downloading.main = false;
  }

  private async downloadProdTeamCSV(): Promise<void> {
    this.downloading.prodTeam = true;

    const exercises: Record<string, ExerciseDocument> = (await ExerciseModel.find()).reduce(
      (accumulator, exercise) => ({ ...accumulator, [exercise.id]: exercise }),
      {} as Record<string, ExerciseDocument>,
    );

    const workoutCSVs = this.results.map((workout, index) => {
      const workoutCSV: any[][] = [];
      const workoutCSVHeaders: string[] = [];

      this.currentPartnerBrand.variationMovementSetup.forEach(variationSetup => {
        const workoutSetup: IPartnerWorkoutBrandVariation = workout.variationWorkouts.find(
          x => x.variation === variationSetup.variation,
        ) as IPartnerWorkoutBrandVariation;

        if (workoutSetup.isDisabled) {
          return;
        }

        if (workoutCSVHeaders.length === 0) {
          workoutCSVHeaders.push(`Workout ${index + 1}`);
        } else {
          workoutCSVHeaders.push('');
        }

        const startingPoint = workoutCSVHeaders.length - 1;

        const maxQPoints = workoutSetup.exerciseSetup.reduce(
          (accumulator: number, exerciseSetup) => Math.max(accumulator, exercises[exerciseSetup.exerciseId].qpoints.length),
          0,
        );

        workoutCSVHeaders.push(variationSetup.variation);

        for (let i = 1; i <= maxQPoints; i++) {
          workoutCSVHeaders.push(`Q${i}`);
        }

        workoutSetup.exerciseSetup.forEach((exerciseSetup, exerciseSetupIndex) => {
          if (!Array.isArray(workoutCSV[exerciseSetupIndex])) {
            workoutCSV.push([]);
          }

          let cellPos = startingPoint;

          workoutCSV[exerciseSetupIndex][cellPos++] = exerciseSetupIndex + 1;
          workoutCSV[exerciseSetupIndex][cellPos++] = exercises[exerciseSetup.exerciseId].name;

          exercises[exerciseSetup.exerciseId].qpoints
            .concat(new Array(maxQPoints - exercises[exerciseSetup.exerciseId].qpoints.length).fill(''))
            .forEach(qpoint => {
              workoutCSV[exerciseSetupIndex][cellPos++] = qpoint;
            });
        });
      });

      workoutCSV.unshift(workoutCSVHeaders);

      return workoutCSV.map(CSV.escapeArray).join('\n');
    });

    saveAs(
      'data:application/csv;charset=utf-8,' + encodeURIComponent(workoutCSVs.join('\n\n')),
      `${this.currentPartnerBrand.name}-Prod-Team-Extract-${moment().format('YYYYMMDD')}.csv`.replace(/\s+/g, '-'),
    );

    this.downloading.prodTeam = false;
  }
}
